<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">打卡记录</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button :type="check == true ? 'primary' : 'cancel'" @click="toClass"
						style="float: right; margin-right: 20px">仅看我的打卡</a-button>
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="类型">
					<a-select placeholder="请选择类型" v-model="query.plan_id" @change="change123()">
						<a-select-option :value="0">
							全部
						</a-select-option>
						<a-select-option :value="item.id" v-for="item in levelList" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<!-- <a-form-model-item label="服务名称">
					<a-input v-model="query.name" placeholder="请输入姓名"></a-input>
				</a-form-model-item> -->
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="plan_id" style="margin-top: 14px" v-viewer>
			<span slot="name" slot-scope="text, record">
				{{ getName(levelList, record.plan_id, "id", "name") }}
			</span>
			<span slot="all_complete_day_data" slot-scope="text, record">
				<div style=" color: #2be0aa;line-height:1.4">{{ record.all_complete_day_data }}{{ record.unit }}</div>
			</span>
			<span slot="complete_day_data" slot-scope="text, record">
				<div style=" color: #46e02b;line-height:1.4">{{ record.complete_day_data }}{{ record.unit }}</div>
			</span>
			<span slot="remark" slot-scope="text, record">
				<a-tooltip>
					<template slot="title">
						<span>{{ record.remark }}</span>
					</template>
					<div>
						{{ record.remark.substr(0, 10) }}
					</div>
				</a-tooltip>
			</span>
			<span slot="day_data" slot-scope="text, record">
				{{ record.day_data }}{{ record.unit }}
			</span>
			<span slot="data" slot-scope="text, record">
				{{ record.year }}年{{ record.mouth }}月{{ record.day }}日（{{ week[record.week] }}）
			</span>
			<span slot="yj_count" slot-scope="text, record">
				{{ record.yj_count }}{{ record.unit }}
			</span>
			<span slot="db" slot-scope="text, record">
				<div v-if="record.complete_day_data >= record.day_data" style=" color: #46e02b;line-height:1.4">今日已达标</div>
				<div v-if="record.complete_day_data < record.day_data" style=" colorrgb(231, 5, 5)2b;line-height:1.4">今日未达标
				</div>
			</span>
		</a-table>

	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		let validateClass = (rule, value, callback) => {
			if (value) {
				callback(); // 通过验证
			} else {
				callback(new Error('请选择分类')); // 验证失败，返回错误信息
			}
		};
		let validateMoney = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入金额'));
			} else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
				callback(new Error('请输入正确的金额'));
			} else {
				callback();
			}
		};
		return {
			query: {
				page: 1,
				limit: 10
			},
			classQuery: {
				page: 1,
				limit: 10
			},
			week: [
				"周日",
				"周一",
				"周二",
				"周三",
				"周四",
				"周五",
				"周六",
			],
			customer_id: null,
			pagetotal: 0,
			classPagetotal: 0,
			classList: [],
			checkValue: [],
			packageList: [],
			tags: ['Tag1', 'Tag2', 'Tag3'],
			inputValue: '',
			visible1: false,
			visible2: false,
			visible3: false,
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			other: '',
			customerForm: {
				name: '',
				money: null,
				content: '',
			},
			service_id: null,
			classForm: { name: '' },
			class_id: null,


			columns: [
				{
					title: '计划名称',
					scopedSlots: {
						customRender: 'name'
					}
				},
				// {
				// 	title: '截至今日预计要完成',
				// 	scopedSlots: {
				// 		customRender: 'yj_count',
				// 	},
				// },
				// {
				// 	title: '每日推荐完成',
				// 	scopedSlots: {
				// 		customRender: 'day_data',
				// 	},
				// },
				{
					title: '备注',
					scopedSlots: {
						customRender: 'remark',
					},
				},
				{
					title: '任务归属人',
					dataIndex: 'do_name',
				},
				{
					title: '打卡日期',
					scopedSlots: {
						customRender: 'data',
					},
				},
				{
					title: '打卡时间',
					dataIndex: 'create_at_str',
				},
				{
					title: '今日是否达标',
					scopedSlots: {
						customRender: 'db',
					},
				},
				{
					title: '本次完成',
					scopedSlots: {
						customRender: 'complete_day_data'
					}
				},
				{
					title: '总完成',
					scopedSlots: {
						customRender: 'all_complete_day_data'
					}
				},
			],
			classColumns: [
				{
					title: '名称',
					dataIndex: 'name',
					customRender: (text, record, index) => {
						return {
							children: text,
							attrs: {
								style: 'width: 60%;', // 指定列宽度为 30%
							},
						};
					},
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},],
			data: [],
			levelList: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
		this.getLevelList()
	},
	methods: {
		change123() {
			this.getList()
		},
		toClass() {
			if (this.query.im == 1) {
				this.query.im = 0
				this.check = false
			} else {
				this.query.im = 1
				this.check = true
			}
			this.getList()
		},
		toEditClass(record) {
			this.class_id = record.class_id
			this.$post(domain + '/admin/v1/serviceClass/info', { class_id: record.class_id }).then(res => {
				if (res.code === 0) {
					this.classForm = res.data
					this.visible3 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toEdit(record) {
			this.service_id = record.service_id
			this.$post(domain + '/admin/v1/service/info', { service_id: record.service_id }).then(res => {
				if (res.code === 0) {
					this.customerForm = res.data
					this.visible1 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		delClass(class_id) {
			this.$post(domain + '/admin/v1/serviceClass/delete', { "class_id": class_id }).then(res => {
				if (res.code === 0) {
					this.$message.success("删除成功")
					this.getClassList()
					this.getLevelList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		submitForm() {
			this.$refs.customerForm.validate((valid) => {
				if (valid) {
					let action = "";
					if (this.service_id != null) {
						action = "update"
						this.customerForm.service_id = this.service_id
					} else {
						action = "add"
					}
					this.$post(domain + '/admin/v1/service/' + action, this.customerForm).then(res => {
						if (res.code === 0) {
							this.$message.success(res.msg)
							this.visible1 = false
							this.initForm()
							this.getList()
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			});
		},

		addClass() {
			this.visible3 = true
		},
		getClassList() {
			this.$post(domain + '/admin/v1/serviceClass/list', this.classQuery).then(res => {
				if (res.code === 0) {
					this.classPagetotal = res.data.total
					this.classList = res.data.list
					this.visible2 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toPackage(record) {
			this.$post(domain + '/admin/v1/customer/memberPackage', { "customer_id": record.customer_id }).then(res => {
				if (res.code === 0) {
					this.packageList = res.data
					this.visible3 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		onClose3() {
			this.visible3 = false
			this.initClassForm()
		},
		onClose2() {
			this.visible2 = false
		},
		initClassForm() {
			this.class_id = null
			this.classForm = {
				name: ''
			}
		},
		handleClose(removedTag) {
			const tags = this.customerForm.tag.filter(tag => tag !== removedTag);
			this.customerForm.tag = tags;
		},
		handleInputConfirm() {
			const inputValue = this.inputValue;
			let tags = JSON.parse(JSON.stringify(this.customerForm.tag));
			if (inputValue && tags.indexOf(inputValue) === -1) {
				tags = [...tags, inputValue];
			}
			this.customerForm.tag = tags;
			console.log("customerForm", this.customerForm.tag)
			this.inputValue = '';
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.check = false
			this.getList()
		},
		handleChange(value) {
			console.log(`Selected: ${value}`);
		},
		popupScroll() {
			console.log('popupScroll');
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		del(service_id) {
			this.$post(domain + '/admin/v1/service/delete', { "service_id": service_id }).then(res => {
				if (res.code === 0) {
					this.$message.success("删除成功")
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		add() {
			console.log("customer_id", this.customer_id)
			this.visible1 = true;
		},
		onClose1() {
			this.visible1 = false;
			this.initForm()
		},
		initForm() {
			this.service_id = null
			this.customerForm = {
				name: '',
				money: null,
				content: '',
			}
		},
		toOrder(item) {
			if (item.rent_count > 0) {
				this.$router.push({
					path: '/orderList',
					query: {
						cid: item.customer_id
					}
				})
			} else {
				this.$message.warn("暂未产生订单")
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/learn/clockList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getLevelList() {
			this.$post(domain + '/admin/v1/learn/learnPlanSelect', {
				"all": 1
			}).then(res => {
				if (res.code === 0) {
					this.levelList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		classListChange(e) {
			this.classQuery.page = e.current
			this.getClassList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
